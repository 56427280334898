/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-24 21:45:51
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-25 15:58:54
 */
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
import { wGetImgList } from '@/plugins/utils'

const { mapGetters, mapActions } = createNamespacedHelpers('App/Finance/AccountDetail')

export default {
  name: 'AccountDetail',
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapRootGetters([
      'can_buy_combo'
    ]),

    ...mapGetters([
      'info',
      'getTradeItems'
    ]),

    // 当前行业分类
    curTradeName () {
      const { first_trade_id: id1, second_trade_id: id2 } = this.info
      if (id1) {
        const items = this.getTradeItems(id1, id2)
        return items.map(({ trade_name: name }) => name).join(' / ')
      }
      return ''
    },

    // 营业执照
    businessUrl () {
      if (!Array.isArray(this.info.business_url)) {
        return []
      }
      return this.info.business_url
    }
  },
  async mounted () {
    // 获取行业分类
    await this.getTradeList()
    // 获取资质详情
    await this.getAccountInfo()
    // 更新营业执照
    this.upddateBusinessUrl()
  },
  methods: {

    ...mapActions([
      'getAccountInfo',
      'getTradeList',
      'updateInfo'
    ]),

    /**
     * 预览
     */
    previewImg (file, index) {
      let url = ''
      if (file.response) {
        url = file.response.data
      } else {
        url = file.url
      }
      window.open(url)
    },

    /**
     * 更新营业执照
     */
    upddateBusinessUrl () {
      if (this.info.business_url) {
        const fileList = wGetImgList(this.info.business_url, this.info.business_url_name)
        // console.log(fileList)
        this.updateInfo({
          business_url: fileList
        })
      }
      this.loading = false
    },

    /**
     * 返回上一页
     */
    returnBack () {
      this.$router.go(-1)
    },

    /**
     * 重新编辑
     */
    goToEdit () {
      this.$router.push('/app/finance/accountconfig')
    }
  }
}
