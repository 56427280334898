/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-24 21:46:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 21:56:55
 */
import AccountDetail from './AccountDetail.vue'
export default AccountDetail
